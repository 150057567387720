import "../scss/solutionsDetailPage.scss";
import { IneractiveBtn } from "./BtnInteractive";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import NavigatorArrow from "../assets/leftArrow.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { NAVIGATION_ROUTES } from "../constants/routes";
import CardHolder from "../assets/solutionsDetailsPage/CardHolder.png";
import { useState } from "react";

export const SolutionsDetailsBanner = ({ bannerDetails }) => {
  const navigate = useNavigate()
  return (
    <>
      {bannerDetails.map((ind, i) => {
        return (
          <div key={i}>
            <div className="solutionsDetailsContainer">
              <div className="solutionsDetailSubContainer">
                <div className="bannerSolutionsDetail">
                  <div className="bannerSolutionsDetailEle">
                    <div className="headingDetailsSol">{ind.title}</div>
                    <div className="subHeadingDetailsSol">
                      {ind.innerDescription}
                    </div>
                    <div className="btnBoxSolutionsDetails">
                      <div onClick={()=>navigate(NAVIGATION_ROUTES.CONTACTUS)}>
                      <IneractiveBtn title={"Contact Sales"} />
                      </div>
                      <div style={{ display: "none" }}>
                        <IneractiveBtn title={"View Demo"} btnType={"noFill"} />
                      </div>
                    </div>
                  </div>
                  <div>
                    <img
                      className="detailsSolutionBannerImg"
                      src={ind.url}
                      alt="bannerImg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export const SolutionsMetrices = ({ bannerDetails }) => {
  return (
    <>
      {bannerDetails.map((ind, i) => {
        return (
          <div key={i}className="solutionMetricesContainer">
            <div className="solutionMetricesHeading">{ind.metricsTitle}</div>
            <div className="solutionMetricesSubheading">{ind.metricsBody}</div>
            <div className="solutionsMetricesCardContainer">
              {ind.metricsCard.map((e) => {
                return (
                  <div key={e.id}>
                    <div className="solutionsMetricesCard">
                      <img
                        className="solutionsCardImg"
                        src={
                          process.env.REACT_APP_BASE_LINK +
                          e.metrics_icon.data.attributes.url
                        }
                        alt="cardImg"
                      />
                      <div className="solutionsMetricesCardText">{e.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export const VisualPresentation = ({ bannerDetails }) => {
  const Title = bannerDetails.map((e) => {
    return e.loaderBannerHead;
  });
  if (JSON.stringify(Title) === JSON.stringify([null])) {
    return <></>;
  } else {
    function Example(props) {
      return (
        <div style={{ marginBottom: 0, marginTop: 0, padding: 0 }}>
          <div style={{ marginTop: 0 }}>
            <div style={{ width: "302px", padding: 0 }}>{props.children}</div>
            <div style={{ width: "100%" }}>
              <h3 className="h5">{props.label}</h3>
              <p>{props.description}</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="visulapresentationContainer">
        {bannerDetails.map((ele,ind) => {
          return (
            <>
              <div key={ind}className="loaderHeader">{ele.loaderBannerHead}</div>
              <div className="loaderContainer">
                <div>
                  <Example label="">
                    <CircularProgressbar
                      value={ele.loader1_percentage}
                      text={`${ele.loader1_percentage}%`}
                      styles={buildStyles({
                        textColor: "#D1AEFE",
                        pathColor:
                          "linear-gradient(166deg, #EDF 5.18%, #D1AEFE 93.19%);",
                        trailColor: "#532392",
                      })}
                    />
                  </Example>
                  <div className="loaderDescription">{ele.loader1_title}</div>
                </div>
                <div>
                  <Example label="">
                    <CircularProgressbar
                      value={ele.loader2_percentage}
                      text={`${ele.loader2_percentage}%`}
                      styles={buildStyles({
                        textColor: "#D1AEFE",
                        pathColor:
                          "linear-gradient(166deg, #EDF 5.18%, #D1AEFE 93.19%);",
                        trailColor: "#532392",
                      })}
                    />
                  </Example>
                  <div className="loaderDescription">{ele.loader2_title}</div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  }
};

export const ChartContent = ({ bannerDetails }) => {
  return (
    <>
      {bannerDetails.map((card,ind) => {
        const url =
          process.env.REACT_APP_BASE_LINK + card.ChartImage.attributes.url;
        return (
          <div key={ind} className="chartContentContainer">
            <div className="chartTitle">{card.chartTitle}</div>
            <div
              className="chartDescription"
              dangerouslySetInnerHTML={{
                __html: card.chartDescription,
              }}
            />
            <div className="chartImgContainer">
              <img className="chartImg" src={url} alt="chartImg" />
            </div>
            {card.stepDetailsDescription.map((e, ind) => {
              return (
                <div key={ind}>
                  <div className="chartContentTitle">{e.Title}</div>
                  <div
                    className="chartContentDescription"
                    dangerouslySetInnerHTML={{
                      __html: e.decription,
                    }}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export const SolutionsCaseStudy = ({ CaseSolutionsByTag, bannerDetails }) => {
  const [index, setIndex] = useState(0);
  const TagName = bannerDetails.map((e) => {
    return e.title;
  });
  const SelectedId = bannerDetails.map((e) => {
    return e.title;
  });
  
  const FilterArr = CaseSolutionsByTag.filter((ind) =>
    ind.tag.includes(TagName)
  );
  const FilterId = CaseSolutionsByTag.filter((ind) =>
    ind.tag.includes(SelectedId)
  ).map((filteredInd) => filteredInd.id);


  const [next, setNext] = useState(FilterArr[index]);
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  function handleCaseStudylanding(ind) {
    queryParams.set("id", ind);
    const newSearch = `?${queryParams.toString()}`;
    navigate({ pathname: NAVIGATION_ROUTES.CASESTUDY, search: newSearch });
    window.scrollTo(0, 0);
  }

  const nextIndex = (index + 1) % FilterArr.length; // Calculate the next index
  const prevIndex = (index - 1 + FilterArr.length) % FilterArr.length; // Calculate the previous index

  const handleClickNext = () => {
    setIndex(nextIndex); // Update the index to the next one
    setNext(FilterArr[nextIndex]);
  };

  const handleClickPrevious = () => {
    setIndex(prevIndex); // Update the index to the previous one
    setNext(FilterArr[prevIndex]);
  };
  if (FilterArr.length > 1) {
    return (
      <div className="solutionsCaseStudyContainer">
        <div className="solutionsCaseStudyHeading">Case Study</div>
        <div className="solutionsCaseStudySubHeading">
          Solutions that accentuate progress and bring out the best in business
        </div>
        <div className="solutionsCaseStudyCard">
          <img
            className="solutionsCaseStudyCardImg"
            src={next.img}
            alt="casestudyImg"
          />
          <div className="solutionsCaseStudyCardLeftSection">
            <div className="solutionsCaseStudyCardTitle">{next.tag}</div>
            <div className="solutionsCaseStudyCardDescription">
              {next.description}
            </div>
            <div
              onClick={() => handleCaseStudylanding(next.id)}
              className="caseStudyBtn"
            >
              <IneractiveBtn title={"Learn More"} btnType={"noFill"} />
            </div>
          </div>
        </div>
        <div className="arrowNavigatorTab">
          <img
            onClick={handleClickPrevious}
            className="rotateArrow"
            src={NavigatorArrow}
            alt="arrow"
          />
          <img
            className="pointerMouse"
            onClick={handleClickNext}
            src={NavigatorArrow}
            alt="arrow"
          />
        </div>
      </div>
    );
  } 
  else if(FilterArr.length === 0){
    return <></>
  }else {
  
       
          return (
            <div className="solutionsCaseStudyContainer">
              <div className="solutionsCaseStudyHeading">Case Study</div>
              <div className="solutionsCaseStudySubHeading">
                Solutions that accentuate progress and bring out the best in
                business
              </div>
              <div className="solutionsCaseStudyCard">
                <img
                  className="solutionsCaseStudyCardImg"
                  src={FilterArr[0].img}
                  alt="casestudyImg"
                />
                <div className="solutionsCaseStudyCardLeftSection">
                  <div className="solutionsCaseStudyCardTitle">
                    {FilterArr[0].brandDetails.brandWork}
                  </div>
                  <div className="solutionsCaseStudyCardDescription">
                    {FilterArr[0].description}
                  </div>
                  <div
                    onClick={() => handleCaseStudylanding(FilterId)}
                    className="caseStudyBtn"
                  >
                    <IneractiveBtn title={"Learn More"} btnType={"noFill"} />
                  </div>
                </div>
              </div>
            </div>
          );
  }
};
